@import '../variables';

@font-face {
  font-family: 'ProductSans Regular';
  src: url('../font/Product Sans Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProductSans Bold';
  src: url('../font/Product Sans Bold.ttf') format('truetype');
  font-display: swap;
}

body {
  background-image: url('https://otrasimagenes.s3.amazonaws.com/generales/Natural.png') !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  font-family: ProductSans Regular !important;
}

div::-webkit-scrollbar {
  width: 5px !important;
  height: 0;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

Input {
  height: 38px;
}

.col-pad5 {
  padding: 0 5px !important;
}

.background {
  width: 100%;
  min-height: calc(100% - 100px);
  background-image: url('https://otrasimagenes.s3.amazonaws.com/generales/Mountains.png');;
  background-size: cover;
  background-attachment: fixed;
}

.borderRadius {
  border-radius: 10px;
}

.divider-5 {
  margin: 5px 0 5px 0 !important;
}

.divider-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.no-margin {
  margin: 0 !important;
}

.scroll {
  float: left;
  overflow: auto;
  border-color: white;
  max-height: 100vh;
}

.scroll1 {
  float: left;
  border-color: white;
  max-height: calc(100vh - 320px);
  overflow: auto;
}

.subtitle {
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
  font-weight: lighter;
  margin-bottom: 3px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.thin_scroll {
  scrollbar-width: thin; // auto | thin | none | <length>;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.ui.input {
  text-transform: uppercase;
}

.bg-white {
  background-color: #fff;
  padding: 30px;
}

.bg-white:hover {
  background-color: #f1f1f1;
  padding: 30px;
}

.fb-hover:hover {
  color: #3b5999;
}

.fb-hover {
  color: #000;
}

.wa-hover:hover {
  color: #25D366;
}

.wa-hover {
  color: #000;
}

.mail-hover:hover {
  color: grey;
}

.mail-hover {
  color: #000;
}

.tw-hover:hover {
  color: #55acee;
}

.tw-hover {
  color: #000;
}

@media only screen and (max-width: 1080px) {
  .scroll1 {
    max-height: calc(100vh - 350px);
  }
}

@media only screen and (max-width: 992px) {
  .scroll1 {
    max-height: calc(100vh - 400px);
  }
}

@media only screen and (max-width: 765px) {
  .scroll1 {
    max-height: calc(80vh);
  }
}
